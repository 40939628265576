import Keycloak from "keycloak-js";
import { BehaviorSubject } from "rxjs";

export let keycloak: { instance?: Keycloak; loaded?: Promise<void> } = {};

export interface UserScopes {
  id: string;
  scope: string;
}

export const bootstrap = async (kcInit: {
  url: string;
  realm: string;
  clientId: string;
}) => {
  if (keycloak.instance) return;

  keycloak.instance = new Keycloak({
    ...kcInit,
  });

  // if (kcInit.realm === "petrovina" || kcInit.realm === "cimoagro") {
  //   keycloak.instance.redirectUri = window.location.origin + "/barter";
  // }

  const urlParams = new URLSearchParams(window.location.search);

  const token = urlParams.get("accessToken");
  const refreshToken = urlParams.get("refreshToken");
  const sessionId = urlParams.get("session_state");

  if (token && refreshToken && sessionId) {
    window.localStorage.setItem("token", token);
    window.localStorage.setItem("refreshToken", refreshToken);
    window.localStorage.setItem("sessionId", sessionId);

    // window.location.href = window.location.origin + "/barter";
  }

  const tokenFromStorage = window.localStorage.getItem("token");
  const refreshTokenFromStorage = window.localStorage.getItem("refreshToken");
  const sessionIdFromStorage = window.localStorage.getItem("sessionId");

  keycloak.instance.token = tokenFromStorage;
  keycloak.instance.refreshToken = refreshTokenFromStorage;
  keycloak.instance.sessionId = sessionIdFromStorage;

  const authenticated = await keycloak.instance.init({
    onLoad: "check-sso",
  });

  if (!keycloak.instance.token) await keycloak.instance.login();

  keycloak.instance.onTokenExpired = () => {
    keycloak.instance
        .updateToken(5)
        .then((refreshed) => {
          if (refreshed) {
          } else {
            console.log(
                "Token not refreshed, valid for " +
                Math.round(
                    keycloak.instance.tokenParsed.exp +
                    keycloak.instance.timeSkew -
                    new Date().getTime() / 1000
                ) +
                " seconds"
            );
          }
        })
        .catch(() => {
          console.log("Failed to refresh token");
        });
  };
};

export const showMenu$ = new BehaviorSubject(true);
export const userScopes$ = new BehaviorSubject<UserScopes[]>([]);
